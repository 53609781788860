import DeliveryPromiseQuery from '~/graphql/deliveryPromise.gql'

export type DeliveryPromiseItem = {
  sku: string
  qty: number
}

export type DeliveryPromiseResult = {
  minPromise: string
  maxPromise: string
}

export type DeliveryPromiseSku = {
  sku: string
  stockType: string
  cutoffDateTime: string
  result: DeliveryPromiseResult
}

export type DeliveryPromiseDays = {
  minPromise: number
  maxPromise: number
}

export function useDeliveryPromise(reset: boolean) {
  const _result = useState('deliveryPromise', () => {})
  const skus = useState<DeliveryPromiseItem[]>('deliveryPromiseSkus', () => [])
  const country = useState<string>('deliveryPromiseCountry', () => '')

  if (reset) {
    _result.value = {}
    skus.value = []
  }

  const enabled = computed(() => !!skus.value?.length)

  const input = computed(() => {
    return {
      input: {
        country: country.value,
        items: skus.value || [],
      },
    }
  })

  const {
    onResult,
    loading,
    load,
    error,
  } = useLazyQuery(DeliveryPromiseQuery, input, { clientId: 'customer' })

  onResult((lastResult) => {
    _result.value = lastResult.data
  })

  const result = computed(() => skus.value?.length ? _result.value : [])

  const bestDeliveryPromise = computed(() => {
    return [...result.value?.ttGetDeliveryPromise || []].map((item: never) => {
      return { ...item, cutoffDateTime: new Date(item.cutoffDateTime) }
    })
      ?.filter((item: never) => item.cutoffDateTime > (new Date()))
      ?.sort((itemA: never, itemB: never) => itemA.cutoffDateTime - itemB.cutoffDateTime)
      ?.[0]
  })

  const homeDeliveryPromise = computed(() => {
    return [...result.value?.ttGetDeliveryPromise || []].map((item: never) => {
      return { ...item, cutoffDateTime: new Date(item.cutoffDateTime) }
    })
      ?.filter((item: never) => item.deliveryType === 'home')
      ?.sort((itemA: never, itemB: never) => itemA.cutoffDateTime - itemB.cutoffDateTime)
      ?.[0]
  })

  const consolidated = computed<DeliveryPromiseResult | null>(() =>
    bestDeliveryPromise.value?.consolidatedResult || null,
  )

  const forSku = (sku: string): DeliveryPromiseSku | null => {
    if (!homeDeliveryPromise.value) {
      return null
    }
    return homeDeliveryPromise.value.itemResults?.find((item: never) => item.sku === sku) || null
  }

  return {
    enabled,
    consolidated,
    bestDeliveryPromise,
    homeDeliveryPromise,
    load,
    forSku,
    skus,
    country,
    result,
    loading,
    error,
  }
}
