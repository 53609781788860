import pdpQuery from '~layers/app/graphql/pdp.gql'
import stockQuery from '~/graphql/pdp/stock.gql'

export function usePDP(meta) {
  const productData = useState('productData', () => null)
  const productError = useState<any>('productError', () => null)
  const stockData = useState('stockData', () => ({}))

  const { public: config } = useRuntimeConfig()
  const locale = config.strapiLocale

  const status = 'PUBLISHED'

  const variables = {
    sku: meta.value?.sku,
    locale,
    status,
  }

  async function fetchProductData() {
    const { data, error } = await useAsyncQuery<never>(pdpQuery, variables)

    if (!data.value) {
      return
    }

    productData.value = data.value
    productError.value = error.value
  }

  const pdpUsps = computed(() => {
    return productData?.value?.pdpUsps?.[0]?.Usp || []
  })

  const esProduct = computed(() => {
    return productData?.value?.esProduct
  })

  const m2Product = computed(() => {
    const product = {
      ...(productData?.value?.products?.items?.[0] || {}),
    }
    delete product.categories

    if (stockData.value?.products?.items?.[0]?.variants?.length) {
      product.variants = product.variants?.map((variant, index) => ({ ...variant, product: { ...variant.product, ...stockData.value?.products?.items?.[0]?.variants[index].product } }))
    }
    if (stockData.value?.products?.items?.[0]?.stock_status) {
      product.stockItem = stockData.value?.products?.items?.[0].stockItem
      product.stock_left = stockData.value?.products?.items?.[0].stock_left
      product.stock_status = stockData.value?.products?.items?.[0].stock_status
    }
    if (stockData.value?.products?.items?.[0]?.items) {
      product.items = result.items.map((item, index) => {
        if (stockData.value?.products?.items?.[0]?.items[index]?.product?.variants?.length) {
          return {
            ...item,
            product: {
              ...item.product,
              ...stockData.value?.products?.items?.[0]?.items[index]?.product,
              variants: item.product.variants.map((variant, vIndex) => ({
                ...variant,
                product: { ...variant.product, ...stockData.value?.products?.items?.[0]?.items[index]?.product.variants[vIndex].product },
              })),
            },
          }
        }
        if (stockData.value?.products?.items?.[0]?.items[index]?.product?.stock_status) {
          return { ...item, product: { ...item.product, ...stockData.value.products.items[0].items[index].product } }
        }
        return { ...item }
      })
    }
    return product
  })

  const crosssellProducts = computed(() =>
    m2Product.value?.crosssell_products?.map(item => item.product) || [],
  )

  const upsellProducts = computed(() =>
    m2Product.value?.upsell_products?.map(item => item.product) || [],
  )

  const relatedProducts = computed(() =>
    m2Product.value?.related_products?.map(item => item.product) || [],
  )

  const interestingProducts = computed(() =>
    m2Product.value?.interesting_products?.map(item => item.product) || [],
  )

  const categories = computed(() => {
    return [
      ...(productData?.value?.products?.items?.[0]?.categories || []),
    ].filter(item => item.url_path).sort((a: never, b: never) => b.level - a.level).map((category: never) => {
      return {
        ...category,
        url: category.url_path,
        label: category.name,
        level: category.level,
      }
    })
  })

  const parentCategories = computed(() => {
    let parent: never = {}
    const parents: never[] = []

    categories.value.forEach((category) => {
      if (category.url && (!parent.url || parent.url.startsWith(category.url))) {
        parent = category
        parents.push(category)
      }
    })

    return parents
  })

  const attributes = computed<Record<string, string | number | null>>(() => {
    return m2Product.value?.attributeGroups?.reduce((acc: never[], item: never) => {
      return acc.concat(item.attributes)
    }, []).reduce((acc: Record<string, string | number | null>, item: never) => {
      acc[item.code] = item.value
      return acc
    }, {})
  })

  const {
    onResult: onLoadedStockData,
    load: loadStockData,
    loading: pendingStock,
  } = useLazyQuery(stockQuery, { sku: meta.value?.sku }, { clientId: 'customer' })

  onLoadedStockData((result) => {
    stockData.value = result.data
  })

  return {
    // meta: route.meta,
    data: productData,
    esProduct,
    m2Product,
    categories,
    parentCategories,
    attributes,
    error: productError,
    crosssellProducts,
    upsellProducts,
    relatedProducts,
    interestingProducts,
    pdpUsps,
    stockData,
    loadStockData,
    pendingStock,
    fetchProductData,
  }
}
