type Item = {
  src: string
  alt?: string
  provider?: string
}

type MediaType = 'image' | 'video' | 'unknown' // | '360' | 'youtube' | 'vimeo'

type MediaItem = Item & {
  type: MediaType
  index: number
}

type ImageOptions = {
  width?: number
  height?: number
  quality?: number
}

type GalleryOptions = {
  media?: Item[]
  index?: number
  variant?: string
}

export function useUiMediaGallery({
  media = [],
  index = 1,
  variant = '',
}: GalleryOptions = {}) {
  const galleryItems = useState<MediaItem[]>('galleryItems', () => [])
  const activeItem = useState<MediaItem | null>('activeItem', () => null)

  const gallery = useState<HTMLElement | null>('gallery', () => null)
  const thumbs = useState<HTMLElement | null>('thumbs', () => null)

  const scrollableThumbs = useState<boolean>('scrollableThumbs', () => false)

  function setItem(index: number, behavior: ScrollBehavior = 'instant') {
    const newItem = galleryItems.value.find(
      (item: MediaItem) => item.index === index,
    )

    if (!newItem) {
      console.error('Can\'t find galleryItem with index of: ', index)
      return
    }

    const elm = gallery.value?.querySelector(
      `[data-item="${index}"]`,
    ) as HTMLElement

    gallery.value?.scroll({
      top: 0,
      left: elm?.offsetLeft || 0,
      behavior,
    // behavior: 'smooth',
    })

    activeItem.value = newItem
  }

  function getImageFromProvider(item: MediaItem, options?: ImageOptions): string {
  // const { $config } = useContext()
    const params: string[] = []
    switch (item.provider) {
      case 'default':
      case 'xcdn':

        if (options?.width && options?.height) {
          params.push(`f=rs:fit:${options.width}:${options.height}:0:0`)
        }
        else if (options?.width) {
          params.push(`f=rs:fit:${options.width}:0:0:0`)
        }
        else if (options?.height) {
          params.push(`f=rs:fit:0:${options.height}:0:0`)
        }

        params.push(`q:${options?.quality || 100}`)
        params.push(`sm:${options?.stripmetadata || 1}`)
        params.push(`scp:${options?.stripcolorprofile || 1}`)

        return `${item.src}${params.length ? '?' + params.join('/') : ''}`
      case 'scayle':
        if (options?.width) {
          params.push(`width=${options.width}`)
        }

        if (options?.height) {
          params.push(`heigth=${options.height}`)
        }

        if (options?.quality) {
          params.push(`quality=${options.quality}`)
        }
        return `${item.src}${params.length ? '?' + params.join('&') : ''}`
      case 'contentful':
        params.push('f=faces')
        params.push('fit=fill')

        if (options?.width) {
          params.push(`w=${options.width}`)
        }

        if (options?.height) {
          params.push(`h=${options.height}`)
        }

        return `${item.src}${params.length ? '?' + params.join('&') : ''}`
      default:
        return item.src
    }
  }

  if (media?.length) {
    galleryItems.value = media.map((item: Item, index: number): MediaItem => {
      const types: any = {
        '.jpg': 'image',
        '.jpeg': 'image',
        '.png': 'image',
        '.webp': 'image',
        '.avif': 'image',
        '.gif': 'image',

        '.mp4': 'video',
        'youtu.be': 'video',
        'youtube': 'video',
        'vimeo': 'video',
      }

      const typeKey: string
      = Object.keys(types).find(type => item.src.includes(type)) || ''

      const type = typeKey ? types[typeKey] : 'unknown'

      return {
        ...item,
        type,
        index: index + 1,
        provider: item.provider || 'default',
      }
    })

    activeItem.value
      = galleryItems.value.find((item: MediaItem) => item.index === index) || null
  }

  return {
    gallery,
    galleryItems,
    activeItem,
    setItem,
    thumbs,
    scrollableThumbs,
    getImageFromProvider,
    variant,
  }
}

export type { Item, MediaItem, ImageOptions }
